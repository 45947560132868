import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  AiFillCaretDown,
  AiFillCaretRight,
  AiOutlineHeart,
  AiFillHeart,
} from "react-icons/ai";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { MdKeyboardArrowLeft } from "react-icons/md";
import "./ModuleProgress.css";
import { useParams, Link, Navigate } from "react-router-dom";
import {
  getModule,
  getModuleAccess,
  Module,
  Module as ModuleType,
} from "../../api/ModulesApi";
import { getSubjects, Subject } from "../../api/SubjectsApi";
import { getLessonsByModuleAndSubject, Lesson } from "../../api/LessonsApi";
import {
  addModuleProgress,
  addModuleTime,
  getCurrentUser,
  getModuleProgress,
  ModuleProgress as Progress,
  User,
} from "../../api/UserApi";
import { useEffectOnce, useLocation } from "react-use";
import reactStringReplace from "react-string-replace";
import { LoadTest } from "../../Tests/LoadTest";
import { getTest, Test } from "../../api/TestApi";
import { lessonTypeToImage, typeStringToLessonType } from "../../Utils";
import { getDecodedToken, loggedIn } from "../../api/AuthApi";
import { TableForm } from "../../Exercises/TableForm";
import { SingleInput } from "../../Exercises/SingleInput";
import { ItemSelection } from "../../Exercises/ItemSelection";
import { ItemSelectionResults } from "../../Exercises/ItemSelectionResults";
import { SmallSingleInput } from "../../Exercises/SmallSingleInput";
import { Dropdown } from "../../Exercises/Dropdown";
import { Evaluation } from "../../Exercises/Evaluation";
import { Video } from "../../Exercises/Video";
import { Audio } from "../../Exercises/Audio";
import { Image } from "../../Exercises/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faPrint } from "@fortawesome/free-solid-svg-icons/faPrint";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { useReactToPrint } from "react-to-print";
import { Print } from "../../Print";
import {
  createFavorite,
  deleteFavorite,
  FavoriteType,
  getFavorites,
} from "../../api/FavoritesApi";
import { useTranslation } from "react-i18next";
import { ZelfbeeldTest } from "../../Exercises/ZelfbeeldTest";
import { PerfectionistTest } from "../../Exercises/PerfectionistTest";
import { MoodForm } from "../../Exercises/MoodForm";
import { DetoxText } from "../../Exercises/DetoxTest";
import { BoetseerForm } from "../../Exercises/BoetseerForm";
import { RisicoTest } from "../../Exercises/RisicoTest";
import { LtmForm } from "../../Exercises/LtmForm";
import { VlaggenForm } from "../../Exercises/VlaggenForm";
import { ItemSelectionColumn } from "../../Exercises/ItemSelectionColumn";
import { MutliTableForm } from "../../Exercises/MultiTableForm";
import { TableFormResults } from "../../Exercises/TableFormResults";
import { ChallengeToughts } from "../../Exercises/ChallengeToughts";
import { isMobile } from "react-device-detect";
import { SmallSingleInputWithText } from "../../Exercises/SmallSingleInputWithText";
import { Image2 } from "../../Exercises/Image2";
import { ItemSelectionResults2 } from "../../Exercises/ItemSelectionResults2";

let interval: any;

export function ModuleProgress() {
  const { moduleId, subjectId, lessonId } = useParams<{
    lessonId: string;
    moduleId: string;
    subjectId: string;
  }>();

  const location = useLocation();
  const [module, setModule] = useState<ModuleType>();
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [moduleProgress, setModuleProgress] = useState<Progress[]>([]);
  const [test, setTest] = useState<Test>();
  const [user, setUser] = useState<User | null>(null);
  const [favorites, setFavorites] = useState<FavoriteType[]>([]);
  const [moduleAccess, setModuleAccess] = useState<null | string[]>(null);

  const { i18n } = useTranslation();

  const filteredLessons = lessons.filter((item) => {
    return (
      (i18n.language === "nl" && item.nameNL) ||
      (i18n.language === "en" && item.nameEN) ||
      (i18n.language === "fr" && item.nameFR)
    );
  });

  const currentLesson = filteredLessons.find((l) => l.id === lessonId);
  const currentSubject = subjects.find((s) => s.id === subjectId);
  const currentIndex = filteredLessons.map((l) => l.id).indexOf(lessonId);
  const prevLesson =
    currentIndex > 0 ? filteredLessons[currentIndex - 1] : null;

  const nextLesson =
    currentIndex < filteredLessons.length - 1
      ? filteredLessons[currentIndex + 1]
      : null;

  const componentRef = useRef(null);
  const reactToPrint = useReactToPrint({ contentRef: componentRef });

  useEffectOnce(() => {
    getCurrentUser().then((result) => {
      setUser(result);
    });

    getFavorites().then((result) => {
      setFavorites(result);
    });

    getModule(moduleId).then((result) => {
      setModule(result);
    });

    getSubjects(moduleId).then((result) => {
      setSubjects(result);
    });

    getModuleAccess().then((result) => {
      setModuleAccess(result);
    });

    getModuleProgress(moduleId).then(async (result) => {
      setModuleProgress(result);

      if (!result.find((r) => r.lessonId === lessonId)) {
        const progress = await addModuleProgress(moduleId, subjectId, lessonId);

        if (result) {
          setModuleProgress(moduleProgress.concat(progress));
        }
      }
    });
  });

  useEffect(() => {
    interval = setInterval(
      async () => {
        await addModuleTime(moduleId, 1);
      },
      1000 * 60 * 1,
    );

    return () => {
      clearInterval(interval);
    };
  });

  async function fetchLessons() {
    const tempLessons: Lesson[] = [];

    for (const subject of subjects) {
      const subjectLessons = await getLessonsByModuleAndSubject(
        moduleId,
        subject.id,
      );

      const foundLesson = subjectLessons.find((l) => l.id === lessonId);

      if (foundLesson) {
        const regex = /<p>[\s]*\[test id=&quot;([^<]*)&quot;\][\s]*<\/p>/gm;
        const result = regex.exec(
          i18n.language === "nl"
            ? foundLesson.contentNL
            : i18n.language === "en"
            ? foundLesson.contentEN
            : foundLesson.contentFR,
        );

        if (result && result[1]) {
          const foundTest = await getTest(result[1]);

          setTest(foundTest);
        }
      }

      tempLessons.push(...subjectLessons);
    }

    setLessons(tempLessons);
  }

  useEffect(() => {
    if (subjects.length === 0) {
      return;
    }

    fetchLessons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjects, i18n]);

  if (!moduleAccess) {
    return null;
  }

  if (!module) {
    return null;
  }

  if (!moduleAccess.includes(moduleId) && module.price !== 0) {
    return <Navigate replace to={`/modules/${moduleId}`} />;
  }

  if (!loggedIn()) {
    return <Navigate replace to={`/login?redirect=${location.pathname}`} />;
  }

  function replaceText(content: string) {
    let replaced = reactStringReplace(
      content,
      /<p>[\s]*\[test id=&quot;([^<]*)&quot;\][\s]*<\/p>/gm,
      () => {
        if (!test) {
          return "";
        }

        return (
          <div className="testview">
            <LoadTest test={test} />
          </div>
        );
      },
    );

    replaced = reactStringReplace(
      replaced,
      /<p>[\s]*\[test id="([^<]*)"\][\s]*<\/p>/gm,
      () => {
        if (!test) {
          return "";
        }

        return (
          <div className="testview">
            <LoadTest test={test} />
          </div>
        );
      },
    );

    replaced = reactStringReplace(
      replaced,
      /<p>[\s]*\[table-form columns=&quot;([^<]*)&quot;\][\s]*<\/p>/gm,
      (match, i) => {
        return <TableForm columns={match.split(",")} id={i.toString()} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /<p>[\s]*\[table-form columns="([^<]*)"\][\s]*<\/p>/gm,
      (match, i) => {
        return <TableForm columns={match.split(",")} id={i.toString()} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /<p>[\s]*\[table-form-results (columns=&quot;[^<]*&quot; id=&quot;[^<]*&quot; lesson_id=&quot;[^<]*&quot;)\][\s]*<\/p>/gm,
      (match) => {
        return <TableFormResults match={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[multi-table-form values=&quot;(.*?)&quot;\]/gm,
      (match) => {
        return (
          <MutliTableForm
            values={match
              .replaceAll("&#x27;", "'")
              .split("|")
              .map((item) => item.split("#"))}
          />
        );
      },
    );

    replaced = reactStringReplace(
      replaced,
      /(\[single-input\])/gm,
      (match, i) => {
        return <SingleInput id={i.toString()} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /(\[single-form\])/gm,
      (match, i) => {
        return <SingleInput id={i.toString()} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[item-selection id=&quot;([^<]*)&quot;\]/gm,
      (match) => {
        return <ItemSelection id={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[item-selection id="([^<]*)"\]/gm,
      (match) => {
        return <ItemSelection id={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /<p>[\s]*\[item-selection-column id=&quot;([^<]*)&quot;\][\s]*<\/p>/gm,
      (match) => {
        return <ItemSelectionColumn id={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[item-selection-results (id=&quot;[^<]*&quot; lesson_id=&quot;[^<]*&quot;)\]/gm,
      (match) => {
        return <ItemSelectionResults match={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[item-selection-results (id="[^<]*" lesson_id="[^<]*")\]/gm,
      (match) => {
        return <ItemSelectionResults2 match={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /(\[small-single-input\])/gm,
      (match, i) => {
        return <SmallSingleInput id={i.toString()} />;
      },
    );

    replaced = reactStringReplace(replaced, /(\[zelfbeeldtest\])/gm, () => {
      return <ZelfbeeldTest />;
    });

    replaced = reactStringReplace(replaced, /(\[risicotest\])/gm, () => {
      return <RisicoTest />;
    });

    replaced = reactStringReplace(replaced, /(\[detoxtest\])/gm, () => {
      return <DetoxText />;
    });

    replaced = reactStringReplace(replaced, /(\[perfectionisttest\])/gm, () => {
      return <PerfectionistTest />;
    });

    replaced = reactStringReplace(replaced, /(\[risicotest\])/gm, () => {
      return <RisicoTest />;
    });

    replaced = reactStringReplace(replaced, /(\[vlaggen\])/gm, (match, i) => {
      return <VlaggenForm id={i.toString()} />;
    });

    replaced = reactStringReplace(
      replaced,
      /(\[boetseerform\])/gm,
      (match, i) => {
        return <BoetseerForm id={i.toString()} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[ltm (type=&quot;[^<]*&quot; lesson_id=&quot;[^<]*&quot;)\]/gm,
      (match, i) => {
        return <LtmForm id={i.toString()} match={match} />;
      },
    );

    replaced = reactStringReplace(replaced, /(\[mood-form\])/gm, (match, i) => {
      return <MoodForm id={i.toString()} />;
    });

    replaced = reactStringReplace(
      replaced,
      /(\[small-single-form\])/gm,
      (match, i) => {
        return <SmallSingleInput id={i.toString()} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[small-single-input text=&quot;([^<]*)&quot;\]/gm,
      (match, i) => {
        return <SmallSingleInputWithText id={i.toString()} text={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[faq_start\](.*?)\[faq_end\]/gim,
      (match) => {
        return <Dropdown text={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[video url=&quot;([^<]*)&quot;\]/gm,
      (match) => {
        return <Video url={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[video url="([^<]*)"\]/gm,
      (match) => {
        return <Video url={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[audio url=&quot;([^<]*)&quot;\]/gm,
      (match) => {
        return <Audio url={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[audio url="([^<]*)"\]/gm,
      (match) => {
        return <Audio url={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[(?:(?:picture)|(?:image)) (url="[^<]*"(?:\s*width="[^<]*")?(?:\s*height="[^<]*")?(?:\s*float="[^<]*")?(?:\s*position="[^<]*")?)\]/gm,
      (match) => {
        return <Image url={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[image (url=&quot;[^<]*&quot;)\]/gm,
      (match) => {
        return <Image2 url={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[picture (url=&quot;[^<]*&quot;\s*width=&quot;[^<]*&quot;\s*height=&quot;[^<]*&quot;\s*float=&quot;[^<]*&quot;)\]/gm,
      (match) => {
        return <Image2 url={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /\[picture (url=&quot;[^<]*&quot;)\]/gm,
      (match) => {
        return <Image2 url={match} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /(\[thoughts-form\])/gm,
      (match, i) => {
        return <ChallengeToughts id={i.toString()} />;
      },
    );

    replaced = reactStringReplace(replaced, /(\[evaluation-form\])/gm, () => {
      return <Evaluation />;
    });

    return replaced;
  }

  const htmltoText = (html: string) => {
    let text = html;

    text = text.replace(/\n/gi, "");
    text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
    text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
    text = text.replace(/<a.*?href="(.*?)[?"].*?>(.*?)<\/a.*?>/gi, " $2 $1 ");
    text = text.replace(/<\/div>/gi, "\n\n");
    text = text.replace(/<\/h[0-9]>/gi, "\n\n");
    text = text.replace(/<\/li>/gi, "\n");
    text = text.replace(/<li.*?>/gi, "  *  ");
    text = text.replace(/<\/ul>/gi, "\n\n");
    text = text.replace(/<\/p>/gi, "\n\n");
    text = text.replace(/<br\s*[/]?>/gi, "\n");
    text = text.replace(/<[^>]+>/gi, "");
    text = text.replace(/^\s*/gim, "");
    text = text.replace(/ ,/gi, ",");
    text = text.replace(/ +/gi, " ");
    text = text.replace(/\n+/gi, "\n\n");

    return text;
  };

  function Navigator() {
    return (
      <>
        {prevLesson ? (
          <Link
            className="button prev-lesson w-fit"
            onClick={async () => {
              const result = await addModuleProgress(
                prevLesson.moduleId,
                prevLesson.subjectId,
                prevLesson.id,
              );

              if (result && !moduleProgress.includes(result)) {
                setModuleProgress(moduleProgress.concat(result));
              }
            }}
            to={`/modules/${prevLesson.moduleId}/subjects/${prevLesson.subjectId}/lessons/${prevLesson.id}`}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </Link>
        ) : null}
        {nextLesson ? (
          <Link
            className="button yellow next-lesson w-fit"
            onClick={async () => {
              const result = await addModuleProgress(
                nextLesson.moduleId,
                nextLesson.subjectId,
                nextLesson.id,
              );

              if (result && !moduleProgress.includes(result)) {
                setModuleProgress(moduleProgress.concat(result));
              }
            }}
            to={`/modules/${nextLesson.moduleId}/subjects/${nextLesson.subjectId}/lessons/${nextLesson.id}`}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        ) : null}
      </>
    );
  }

  const content = currentLesson
    ? replaceText(
        i18n.language === "nl"
          ? currentLesson.contentNL
              .replace("https://b2b-staging.mindlab.be", "")
              .replaceAll("&amp;nbsp", "<br>")
          : i18n.language === "en"
          ? currentLesson.contentEN.replace(
              "https://b2b-staging.mindlab.be",
              "",
            )
          : currentLesson.contentFR.replace(
              "https://b2b-staging.mindlab.be",
              "",
            ),
      ).map((item) => {
        if (typeof item === "string") {
          return <div dangerouslySetInnerHTML={{ __html: item }} />;
        }

        return item;
      })
    : null;

  return (
    <div className="progress-container">
      <ModuleSidebar
        current={lessonId}
        lessons={filteredLessons}
        module={module}
        onClick={async (subjectId, lessonId) => {
          const result = await addModuleProgress(moduleId, subjectId, lessonId);

          if (result && !moduleProgress.includes(result)) {
            setModuleProgress(moduleProgress.concat(result));
          }
        }}
        subjects={subjects}
        visited={moduleProgress.map((i) => i.lessonId)}
      />
      {currentLesson ? (
        <div className="progress-content">
          <div className="breadcrumb">
            {isMobile ? null : (
              <>
                <Link className="link" to={`/modules/${moduleId}`}>
                  {i18n.language === "nl" && module.titleNL}
                  {i18n.language === "en" && module.titleEN}
                  {i18n.language === "fr" && module.titleFR}
                </Link>{" "}
                <div className="ml-5 mr-5">&gt;</div>
                <div className="link">
                  {" "}
                  {i18n.language === "nl" && currentSubject?.nameNL}
                  {i18n.language === "en" && currentSubject?.nameEN}
                  {i18n.language === "fr" && currentSubject?.nameFR}
                </div>
                <div className="ml-5 mr-5">&gt;</div>
                <div className="link">
                  {" "}
                  {i18n.language === "nl" && currentLesson.nameNL}
                  {i18n.language === "en" && currentLesson.nameEN}
                  {i18n.language === "fr" && currentLesson.nameFR}
                </div>
                <div className="ml-auto flex"></div>
              </>
            )}
            {getDecodedToken().admin ? (
              <Link
                className="link mr-5"
                to={`/dashboard/modules/${currentLesson.moduleId}/subjects/${currentLesson.subjectId}/lessons/${currentLesson.id}`}
              >
                Bewerk module
              </Link>
            ) : null}
            <Navigator />
          </div>
          <div className="actions flex w-100">
            <div className="ml-auto" />
            <div
              onClick={async () => {
                const mappedFavorites = favorites.map((f) => f.lessonId);

                if (mappedFavorites.includes(currentLesson.id)) {
                  await deleteFavorite(currentLesson.id);
                  setFavorites(
                    favorites.filter((f) => f.lessonId !== currentLesson.id),
                  );
                } else {
                  if (!user) {
                    return;
                  }

                  await createFavorite(currentLesson.id);
                  setFavorites(
                    favorites.concat({ lessonId: lessonId, userId: user.id }),
                  );
                }
              }}
            >
              {favorites.map((f) => f.lessonId).includes(currentLesson.id) ? (
                <AiFillHeart />
              ) : (
                <AiOutlineHeart />
              )}
            </div>
            <div className="margin" />
            <div
              onClick={() => {
                reactToPrint();
              }}
            >
              <FontAwesomeIcon icon={faPrint} />
            </div>
            <div className="margin" />
            <a
              href={`mailto:${user?.email}?subject=${encodeURIComponent(
                currentLesson.nameNL,
              )}&body=${encodeURIComponent(
                htmltoText(
                  i18n.language === "nl"
                    ? currentLesson.contentNL
                    : i18n.language === "en"
                    ? currentLesson.contentEN
                    : currentLesson.contentFR,
                ),
              )}`}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
          {content}
          <div style={{ display: "none" }}>
            <Print ref={componentRef}>{content}</Print>
          </div>
          <div className="bottom-navigator">
            <div className="ml-auto flex">
              <Navigator />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function ModuleSidebar({
  module,
  subjects,
  lessons,
  current,
  visited,
  onClick,
}: {
  current: string;
  lessons: Lesson[];
  module: Module | undefined;
  onClick: (subjectId: string, lessonId: string) => void;
  subjects: Subject[];
  visited: string[];
}) {
  const [showSidebar, setShowSidebar] = useState(!isMobile);
  const { t, i18n } = useTranslation("general");

  const percentage = Math.min(
    Math.floor((new Set(visited).size / lessons.length) * 100),
    100,
  );

  return (
    <>
      {isMobile ? (
        showSidebar ? (
          <div
            onClick={() => {
              setShowSidebar(!showSidebar);
            }}
            style={{
              fontSize: "28px",
              marginTop: "30px",
              position: "absolute",
              right: "40px",
            }}
          >
            <BiCollapse />
          </div>
        ) : (
          <div
            onClick={() => {
              setShowSidebar(!showSidebar);
            }}
            style={{
              fontSize: "24px",
              left: "15px",
              marginTop: "30px",
              position: "absolute",
            }}
          >
            <BiExpand />
          </div>
        )
      ) : null}

      {showSidebar ? (
        <div className="module-sidebar-container">
          <div className="ml-5">
            <Link className="button back" to={`/modules/${module?.id}`}>
              <MdKeyboardArrowLeft />
              {t("back_to_module")}
            </Link>
          </div>

          <div className="title">
            {i18n.language === "nl" && module?.titleNL}
            {i18n.language === "en" && module?.titleEN}
            {i18n.language === "fr" && module?.titleFR}
          </div>

          <div className="bar-wrapper">
            <div
              className={`bar`}
              style={{
                width: `${percentage > 100 ? 100 : percentage}%`,
              }}
            />
          </div>

          <div style={{ marginLeft: "30px" }}>
            {`${percentage > 100 ? 100 : percentage}%`} {t("finished")}
          </div>

          {subjects
            .filter((s) => {
              return (
                (i18n.language === "fr" && s.nameFR.trim() !== "") ||
                (i18n.language === "en" && s.nameEN.trim() !== "") ||
                i18n.language === "nl"
              );
            })
            .map((subject) => {
              const subjectLessons = lessons.filter(
                (l) => l.subjectId === subject.id,
              );

              return (
                <ModuleDropdown
                  current={current}
                  key={subject.id}
                  lessons={subjectLessons}
                  onClick={onClick}
                  subject={subject}
                  visited={visited}
                />
              );
            })}
        </div>
      ) : null}
    </>
  );
}

function ModuleDropdown({
  subject,
  lessons,
  current,
  visited,
  onClick,
}: {
  current: string;
  lessons: Lesson[];
  onClick: (subjectId: string, lessonId: string) => void;
  subject: Subject;
  visited: string[];
}) {
  const { lessonId } = useParams<{
    lessonId: string;
    moduleId: string;
    subjectId: string;
  }>();

  const [open, setOpen] = useState(lessons.map((l) => l.id).includes(lessonId));
  const { t, i18n } = useTranslation("general");

  useEffect(() => {
    if (!lessons.map((l) => l.id).includes(lessonId)) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId]);

  return (
    <>
      <div
        className="flex lesson-wrapper"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="lesson">
          {open ? <AiFillCaretDown /> : <AiFillCaretRight />}
          {i18n.language === "nl" && subject.nameNL}
          {i18n.language === "en" && subject.nameEN}
          {i18n.language === "fr" && subject.nameFR}
        </div>
        <div className="lesson-count">
          {lessons.length} {t("subjects")}
        </div>
      </div>
      {open ? (
        <>
          {lessons.map((lesson, index) => {
            const name =
              i18n.language === "nl"
                ? lesson.nameNL
                : i18n.language === "en"
                ? lesson.nameEN
                : lesson.nameFR;

            if (!name || name === "") {
              return null;
            }

            const content = (
              <Fragment>
                <img
                  src={lessonTypeToImage(typeStringToLessonType(lesson.type))}
                />
                <div className="overflow">{name}</div>
                {visited.includes(lesson.id) ? (
                  <div className="ml-auto visited-check">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                ) : null}
              </Fragment>
            );

            if (visited.includes(lesson.id)) {
              return (
                <Link
                  className={`subject ${
                    visited.includes(lesson.id) ? "visited" : ""
                  } ${current === lesson.id ? "active" : ""}`}
                  key={index}
                  onClick={() => {
                    onClick(lesson.subjectId, lesson.id);
                  }}
                  to={`/modules/${lesson.moduleId}/subjects/${subject.id}/lessons/${lesson.id}`}
                >
                  {content}
                </Link>
              );
            }

            return (
              <div
                className={`subject no-pointer ${
                  current === lesson.id ? "active" : ""
                }`}
                key={index}
              >
                {content}
              </div>
            );
          })}
        </>
      ) : null}
    </>
  );
}
