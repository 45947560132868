import { BloomupToken, getDecodedToken, Token } from "./api/AuthApi";
import React from "react";
import { Navigate } from "react-router-dom";
import Audio from "./img/audio.png";
import Exercise from "./img/exercise.png";
import Picture from "./img/picture.png";
import Text from "./img/text.png";
import Video from "./img/video.png";
import Question from "./img/question.png";
import { Quarter } from "./QuarterlyReport/Quarter";
import { DateTime } from "luxon";

export enum LessonType {
  AUDIO = "audio",
  EXERCISE = "exercise",
  PICTURE = "picture",
  QUESTION = "question",
  TEXT = "text",
  VIDEO = "video",
}

export function RedirectIfNotAdmin() {
  const token = getDecodedToken();

  if (!token.admin) {
    return <Navigate to={"/"} />;
  }

  return null;
}

export function RedirectIfNotLoggedIn() {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to={"/"} />;
  }

  return null;
}

export function tokenIsExpired(token: Token | BloomupToken) {
  if (Date.now() >= token.exp * 1000) {
    localStorage.removeItem("token");

    return true;
  }

  return false;
}

export function typeStringToLessonType(type: string) {
  switch (type) {
    case LessonType.TEXT:
      return LessonType.TEXT;

    case LessonType.AUDIO:
      return LessonType.AUDIO;

    case LessonType.EXERCISE:
      return LessonType.EXERCISE;

    case LessonType.QUESTION:
      return LessonType.QUESTION;

    case LessonType.VIDEO:
      return LessonType.VIDEO;

    case LessonType.PICTURE:
      return LessonType.PICTURE;

    default:
      throw new Error("Lesson type is not valid.");
  }
}

export function lessonTypeToImage(type: LessonType) {
  switch (type) {
    case LessonType.TEXT:
      return Text;

    case LessonType.AUDIO:
      return Audio;

    case LessonType.EXERCISE:
      return Exercise;

    case LessonType.QUESTION:
      return Question;

    case LessonType.VIDEO:
      return Video;

    case LessonType.PICTURE:
      return Picture;

    default:
      throw new Error("Lesson type is not valid.");
  }
}

export function isB2C() {
  const type = process.env.NX_PUBLIC_FRONTEND_TYPE;

  return type === "b2c";
}

export function quarterAndYearToFilterQueryString(
  quarter: Quarter,
  year: number,
): string {
  const startMonth = (quarter - 1) * 3 + 1; // month is 0-based in Date, so subtract 1
  const start = DateTime.fromObject(
    {
      day: 1,
      hour: 0,
      minute: 0,
      month: startMonth,
      year: year,
    },
    { zone: "UTC" },
  );

  const rawEnd = DateTime.fromObject(
    {
      day: 2,
      hour: 23,
      minute: 59,
      month: startMonth + 2,
      year: year,
    },
    { zone: "UTC" },
  );

  const end = DateTime.fromObject(
    {
      day: rawEnd.endOf("month").day,
      hour: 23,
      minute: 59,
      month: startMonth + 2,
      year: year,
    },
    { zone: "UTC" },
  );

  return new URLSearchParams({
    end: end.toUTC().toISO() || "",
    start: start.toUTC().toISO() || "",
  }).toString();
}

export function formatNumber(number: number): string {
  if (Number.isInteger(number)) {
    return number.toString(); // Return the number as a string without decimal places
  } else {
    return number.toFixed(1); // Round the number to 1 decimal place using toFixed
  }
}

export function getCorrectUrl(url: string) {
  if (!url) {
    return url;
  }

  if (url.startsWith("http")) {
    return url;
  }

  return process.env.NX_PUBLIC_IMAGE_BASE_URL + url;
}
