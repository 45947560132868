import { AgGridReact } from "ag-grid-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import "./Table.css";
import { ColDef } from "ag-grid-community";

export type RowData<T1, T2, T3, T4> = {
  buttonColValue: T3;
  col1Value: T1;
  col2Value: T2;
  type: T4;
}[];

type ColData<T> = {
  cellRenderer?: ({ value }: { value: T }) => string;
  headerName: string;
};

/*
 * Component used to display a table with two columns and a button column.
 * It takes 3 generic types:
 * - T1: the type of the first column
 * - T2: the type of the second column
 * - T3: the type of the button column
 * - T4: the type of the type attribute
 */
export function Table<T1, T2, T3, T4>({
  rowData,
  col1Data,
  col2Data,
  buttonIcon,
  onButtonClick,
}: {
  buttonIcon: IconDefinition;
  col1Data: ColData<T1>;
  col2Data: ColData<T2>;
  onButtonClick: (value: T3, type: T4) => void;
  rowData: RowData<T1, T2, T3, T4>;
}) {
  const { t } = useTranslation("user-dashboard");

  function TableButtonRenderer({
    value,
    getType,
  }: {
    getType: (value: T3) => T4;
    value: T3;
  }) {
    return (
      <FontAwesomeIcon
        className="table-button"
        icon={buttonIcon}
        onClick={() => onButtonClick(value, getType(value))}
      />
    );
  }

  return (
    <div className="table ag-theme-alpine">
      <AgGridReact<RowData<T1, T2, T3, T4>[0]>
        columnDefs={
          [
            {
              cellClass: "text-cell",
              cellRenderer: col1Data.cellRenderer || undefined,
              field: "col1Value",
              filter: true,
              flex: 2,
              headerName: col1Data.headerName,
              sortable: true,
            },
            {
              cellClass: "text-cell",
              cellRenderer: col2Data.cellRenderer || undefined,
              field: undefined, //"col2Value",
              filter: true,
              flex: 3,
              headerName: col2Data.headerName,
              sort: "desc",
              sortable: true,
            },
            {
              cellClass: "button-cell",
              cellRenderer: TableButtonRenderer,
              cellRendererParams: {
                getType: (id: T3) => {
                  return (
                    rowData.find((r) => r.buttonColValue === id)?.type || ""
                  );
                },
              },
              field: undefined, //"buttonColValue",
              flex: 1,
              headerName: "",
            },
          ] as ColDef[]
        }
        gridOptions={{ rowHeight: 70 }}
        onGridReady={(params) => params.api.sizeColumnsToFit()}
        overlayNoRowsTemplate={t("noData")}
        rowData={rowData}
        suppressMovableColumns
      />
    </div>
  );
}
