import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import DashboardIcon from "./img/dashboard.svg";
import BusinessInfoIcon from "./img/building.svg";
import ReportsIcon from "./img/report.svg";
import DashboardActiveIcon from "./img/dashboard_active.svg";
import BusinessInfoActiveIcon from "./img/building_active.svg";
import ReportsActiveIcon from "./img/report_active.svg";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";

export function BusinessSidebarMobile({
  handleClick,
  hasPremium,
}: {
  handleClick: () => void;
  hasPremium: boolean;
}) {
  const { t } = useTranslation("hr-dashboard");

  function unMountHamburger() {
    document.body.style.overflow = "auto";
  }

  const routes = [
    {
      activeIcon: DashboardActiveIcon,
      icon: DashboardIcon,
      name: t("businessSidebar.dashboard"),
      url: "/dashboard/general",
    },
    {
      activeIcon: BusinessInfoActiveIcon,
      icon: BusinessInfoIcon,
      name: t("businessSidebar.businessInfo"),
      url: "/dashboard/info",
    },
    {
      activeIcon: ReportsActiveIcon,
      icon: ReportsIcon,
      name: t("businessSidebar.reports"),
      url: "/dashboard/reports",
    },
  ];

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => unMountHamburger();
  }, []);

  return (
    <div className="hamburger-menu">
      <RxCross2
        className="business-sidebar-cross mr-auto"
        onClick={handleClick}
        size={30}
      />
      <div className="menu-title">{t("businessSidebar.menu")}</div>
      {routes.map((item) => {
        if (!hasPremium && item.name === "Rapporten") {
          return null;
        }

        return (
          <NavLink
            className="sidebar-item"
            key={item.url}
            onClick={handleClick}
            to={item.url}
          >
            <img alt="icon" className="icon" src={item.icon} />
            <div>{item.name}</div>
          </NavLink>
        );
      })}
    </div>
  );
}
