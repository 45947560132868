import { API_ENDPOINT, getHeaders } from "./AuthApi";

export interface IntelliProveResults {
  acuteMentalStressScore: number | null;
  ansBalance: number | null;
  errorCode: number;
  errorDescription?: string;
  heartRate: number;
  heartRateVariability: number | null;
  id: string;
  mentalHealthRisk: number | null;
  morningReadiness: number | null;
  resonantBreathingScore: number | null;
  respiratoryRate: number | null;
  timestamp: string;
  timestampEpoch: number;
}

function throwErrorIfHttpError(result: { status: number }) {
  if (result.status >= 400 && result.status <= 599) {
    throw new Error("HTTP request failed");
  }
}

// eslint-disable-next-line
export namespace IntelliProveApi {
  export async function getResultsForUser(): Promise<IntelliProveResults[]> {
    const results = await fetch(`${API_ENDPOINT}/intelliprove/results/me`, {
      headers: getHeaders(),
      method: "GET",
    }).then((r) => r.json());

    throwErrorIfHttpError(results);

    if (!results.results) {
      return [];
    }

    return results.results;
  }

  export async function checkScanDue(): Promise<boolean> {
    const response = await fetch(
      `${API_ENDPOINT}/intelliprove/periodic-scan-due`,
      {
        headers: getHeaders(),
        method: "GET",
      },
    ).then((r) => r.json());

    throwErrorIfHttpError(response);

    return response.due;
  }
}
