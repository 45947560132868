import React, { PropsWithChildren, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { getConnectedBusinesses } from "./api/BusinessApi";
import DashboardIcon from "./img/dashboard.svg";
import BusinessInfoIcon from "./img/building.svg";
import ReportsIcon from "./img/report.svg";
import DashboardActiveIcon from "./img/dashboard_active.svg";
import BusinessInfoActiveIcon from "./img/building_active.svg";
import ReportsActiveIcon from "./img/report_active.svg";
import ArrowRight from "./img/arrow-right.svg";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import Hamburger from "./img/hamburger.svg";
import { BusinessSidebarMobile } from "./BusinessSidebarMobile";

export function BusinessSidebar({ children }: PropsWithChildren<any>) {
  const location = useLocation();
  const [hasPremium, setHasPremium] = useState(false);
  const { t, i18n } = useTranslation("hr-dashboard");
  const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false);

  const handleClick = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const routes = [
    {
      activeIcon: DashboardActiveIcon,
      icon: DashboardIcon,
      name: t("businessSidebar.dashboard"),
      url: "/" + i18n.language + "/dashboard/general",
    },
    {
      activeIcon: BusinessInfoActiveIcon,
      icon: BusinessInfoIcon,
      name: t("businessSidebar.businessInfo"),
      url: "/" + i18n.language + "/dashboard/info",
    },
    {
      activeIcon: ReportsActiveIcon,
      icon: ReportsIcon,
      name: t("businessSidebar.reports"),
      url: "/" + i18n.language + "/dashboard/reports",
    },
  ];

  useEffectOnce(() => {
    getConnectedBusinesses().then((businesses) => {
      if (!businesses) {
        return;
      }

      setHasPremium(businesses.filter((b) => b.business.type >= 1).length >= 1);
    });
  });

  return !isMobile ? (
    <div className="flex business-sidebar sidebar-container">
      <div className="sidebar flex flex-column">
        <div className="menu-title">{t("businessSidebar.menu")}</div>
        {routes.map((item) => {
          if (!hasPremium && item.name === "Rapporten") {
            return null;
          }

          return (
            <NavLink
              className={({ isActive }) =>
                `sidebar-item ${isActive ? "active" : ""}`
              }
              key={item.url}
              to={item.url}
            >
              <img
                alt="icon"
                className="icon"
                src={
                  location.pathname === item.url ? item.activeIcon : item.icon
                }
              />
              <div>{item.name}</div>
              {location.pathname.includes(item.url) ? (
                <img alt="arrow" className={"ml-auto"} src={ArrowRight} />
              ) : null}
            </NavLink>
          );
        })}
      </div>
      <div className="content business-content">{children}</div>
    </div>
  ) : (
    <div className="flex flex-column business-sidebar-mobile">
      <div className="content business-content">
        {!hamburgerOpen ? (
          <img
            alt={"menu"}
            className="business-sidebar-hamburger pointer mr-auto"
            onClick={handleClick}
            src={Hamburger}
          />
        ) : (
          <BusinessSidebarMobile
            handleClick={handleClick}
            hasPremium={hasPremium}
          />
        )}
        {children}
      </div>
    </div>
  );
}
