import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loggedIn, login } from "./api/AuthApi";
import { Dialog } from "./Dialog";
import { useTranslation } from "react-i18next";
import { graphql } from "./__generated__";
import { useMutation } from "@apollo/client";

const LoginMutation = graphql(`
  mutation Login($input: LoginV2Input!) {
    loginV2(input: $input) {
      ... on TokenResponse {
        accessToken
        mindlabToken
        refreshToken
      }
      ... on LoginV2FailureResponse {
        reason
      }
    }
  }
`);

function Login({
  setAuthenticated,
}: {
  setAuthenticated: (state: boolean) => void;
}) {
  const [bloomupLogin, { data }] = useMutation(LoginMutation);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorTextKey, setErrorTextKey] = useState<string | undefined>(
    undefined,
  );

  const [needs2FA, setNeeds2FA] = useState(false);
  const [token2FA, setToken2FA] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation("login");

  const searchParams = new URLSearchParams(location.search);
  const redirectSearch = searchParams.get("redirect");
  const redirectUrl = redirectSearch
    ? decodeURIComponent(redirectSearch)
    : "/home";

  // redirect away from this page if user is already logged in
  useEffect(() => {
    if (loggedIn()) {
      setAuthenticated(true);
      navigate(redirectUrl);
    }
  }, [navigate, redirectUrl, setAuthenticated]);

  const handleLogin = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (!process.env.NX_PUBLIC_USE_BLOOMUP_LOGIN) {
        login(email, password, token2FA).then((r) => {
          if (r.error) {
            setErrorTextKey(r.error);
          }

          if (r.needs2FAToken) {
            setNeeds2FA(true);

            return;
          }

          if (r.ok) {
            setAuthenticated(true);
            navigate(redirectUrl);
          }
        });
      } else {
        bloomupLogin({
          onCompleted: (data) => {
            if (
              data.loginV2.__typename === "LoginV2FailureResponse" &&
              data.loginV2.reason === "NEEDS_2FA"
            ) {
              setNeeds2FA(true);
            } else if (data.loginV2.__typename === "LoginV2FailureResponse") {
              setErrorTextKey("incorrectCredentials");
            } else if (data.loginV2.__typename === "TokenResponse") {
              // Temporary fallback while mindlabToken is optional on the graph
              if (!data.loginV2.mindlabToken) {
                setErrorTextKey("incorrectCredentials");

                return;
              }

              localStorage.setItem("token", data.loginV2.mindlabToken);

              if (data.loginV2.accessToken) {
                localStorage.setItem(
                  "bloomupAccessToken",
                  data.loginV2.accessToken,
                );
              }

              if (data.loginV2.refreshToken) {
                localStorage.setItem(
                  "bloomupRefreshToken",
                  data.loginV2.refreshToken,
                );
              }

              setAuthenticated(true);
              navigate(redirectUrl);
            }
          },
          onError: () => {
            setErrorTextKey("networkError");
          },
          variables: { input: { email, password, token2FA } },
        });
      }
    },
    [
      bloomupLogin,
      email,
      navigate,
      password,
      redirectUrl,
      setAuthenticated,
      token2FA,
    ],
  );

  return (
    <div className="auth login">
      <h2>{t("login")}</h2>
      {errorTextKey ? <div>{t(errorTextKey)}</div> : null}
      <form className="auth-form" onSubmit={handleLogin}>
        <label>{t("email")}</label>
        <input
          className="input"
          onChange={(input) => {
            setEmail(input.target.value);
          }}
          placeholder={t("email")}
          required={true}
          type="email"
          value={email}
        />
        <label>{t("password")}</label>
        <input
          className="input"
          onChange={(input) => {
            setPassword(input.target.value);
          }}
          placeholder={t("password")}
          required={true}
          type="password"
          value={password}
        />
        <div className="flex">
          <Link className="register" to={`/register${location.search}`}>
            {t("create_account")}
          </Link>
          <Link className="forgot" to="/password/reset">
            {t("forgot_password")}
          </Link>
        </div>
        <button className="button mt-4" type="submit">
          {t("login")}
        </button>
      </form>

      <Dialog
        isOpen={needs2FA}
        onRequestClose={() => {
          setNeeds2FA(false);
        }}
        padding={true}
        size="small"
        title={t("2FATokenExpected")}
      >
        <form onSubmit={handleLogin}>
          <label>{t("fill2fa")}</label>
          <input
            onChange={(input) => {
              setToken2FA(input.target.value);
            }}
            placeholder="Token"
            required={true}
            type="text"
            value={token2FA}
          />
          <button className="button mt-4" type="submit">
            {t("send")}
          </button>
          {data?.loginV2.__typename === "LoginV2FailureResponse" &&
            data.loginV2.reason === "INCORRECT_2FA" && (
              <div className="mt-4">{t("login:2FANotValid")}</div>
            )}
        </form>
      </Dialog>
    </div>
  );
}

export default Login;
